<template>
  <div>
    <div>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          img-src="../../public/art-4-1-caste.jpg"
        ></b-carousel-slide>
        <b-carousel-slide img-src="../../public/art-4-2-misogyny.jpg">
        </b-carousel-slide>
        <b-carousel-slide
          img-src="../../public/art-4-3-stereotyping.jpg"
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <div style="padding: 1em">
      <h3>About the work</h3>
    </div>
    <div style="padding: 1em" class="art-description">
      <p>
        With the advent of OTT platforms, we perhaps find ourselves witnessing
        yet another boom; one that's drastically changing our viewing habits.
        ‘This Scene May Contain ____” ponders abstract representations of a
        future where algorithms can process mass media and expose problematic
        content long associated with Bollywood cinema—a foremost influencer of
        public opinion and behaviour—in real-time. A precedent for such
        possibilities was already set when all digital portals in India were
        recently announced to have come under the purview of the Ministry of
        Information and Broadcasting. The department suggested the use of
        automated decision-making in separating out “lawful” and “unlawful”
        content. A future was thus dreamt to flip the present on its own head,
        where ADM educates rather than censors. Consequently, a few of the most
        commonly observed phobias, propaganda, and -isms have been selected and
        reviewed alongside corresponding findings and statistics. In composition
        and style, 'simpler times' echoed in vintage imagery are juxtaposed with
        the predicted evolution of emerging technologies, which one day, could
        save us from ourselves.
      </p>
    </div>
    <div style="padding: 1em"></div>

    <div style="padding: 1em">
      <h3>About the Artist</h3>
    </div>
    <div style="padding: 1em" class="artist-name">
      <p>Shristi Singh is an artist and illustrator based in Mumbai.</p>
    </div>
    <div style="display:flex; justify-content:center" class="art-link">
      <audio
        controls
        src="/art-4-1.mp3">
            Your browser does not support the
            <code>audio</code> element.
    </audio>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>